// variables
var tlInit = gsap.timeline({ duration: 0.65, paused: true });
var tlAs01 = gsap.timeline({});
gsap.registerPlugin(ScrollTrigger);
var tlAs02 = gsap.timeline({
    paused: true,
    scrollTrigger: { trigger: "#influRequisitos .left" },
});
gsap.registerPlugin(ScrollTrigger);
var tlAs03 = gsap.timeline({
    paused: true,
    scrollTrigger: { trigger: "#influBeneficios .grid .cel" },
});

// hero background animation
if (document.getElementById("hero")) {
    /*
    var tlFloating = new TimelineMax({ repeat: -1 });
    gsap.to(".heroBg02", 5, {
        y: 30,
        repeat: -1,
        ease: "none",
        yoyo: true,
    });
    gsap.to(".heroBg03", 4, {
        y: 30,
        repeat: -1,
        ease: "none",
        yoyo: true,
    });*/
}

gsap.to("#btn-register .icon, #btn-free-register .icon", 1, {
    x: 10,
    repeat: -1,
    ease: "none",
    yoyo: true,
});

// Influencers animation
if (document.getElementById("influHeader")) {
    tlAs01
        .fromTo(
            "#influHeader h2, #influHeader h3",
            0.65,
            {
                x: -80,
                opacity: 0,
            },
            {
                x: 0,
                opacity: 1,
            }
        )
        .fromTo(
            "#influHeader .imgWrp01",
            0.85,
            {
                y: -60,
                rotate: -10,
                opacity: 0,
            },
            {
                y: 0,
                rotate: 0,
                opacity: 1,
            }
        )
        .fromTo(
            "#influHeader .imgWrp02",
            0.85,
            {
                x: 60,
                rotate: 10,
                opacity: 0,
            },
            {
                x: 0,
                rotate: 0,
                opacity: 1,
            },
            "-=0.85"
        )
        .fromTo(
            "#influHeader .imgWrp03",
            0.85,
            {
                y: 60,
                rotate: 10,
                opacity: 0,
            },
            {
                y: 0,
                rotate: 0,
                opacity: 1,
            },
            "-=0.85"
        )
        .fromTo(
            "#influHeader .imgWrp04",
            0.85,
            {
                x: -60,
                rotate: 10,
                opacity: 0,
            },
            {
                x: 0,
                rotate: 0,
                opacity: 1,
            },
            "-=0.85"
        );

    tlAs02
        .fromTo(
            "#influRequisitos .left",
            0.65,
            {
                x: -80,
                opacity: 0,
            },
            {
                x: 0,
                opacity: 1,
            }
        )
        .fromTo(
            "#influRequisitos .right",
            0.65,
            {
                x: 80,
                opacity: 0,
            },
            {
                x: 0,
                opacity: 1,
            },
            "-=0.65"
        );

    tlAs03.fromTo(
        "#influBeneficios .cel",
        1,
        {
            y: 80,
            opacity: 0,
        },
        {
            stagger: 0.1,
            y: 0,
            opacity: 1,
        }
    );

    gsap.to("#btn-influ-reg .icon", 1, {
        x: 10,
        repeat: -1,
        ease: "none",
        yoyo: true,
    });
}

// jquery initial
$(document).ready(function () {
    // menu de movil
    $("#mobile-menu, #main>a").on("click", function () {
        $("#mobile-menu").toggleClass("open");
        $("#main").toggleClass("open");
        $("body").toggleClass("mobile-menu-open");
    });
    // boton copiar
    $("#copy-direction-home").click(function () {
        // Utilizamos un textarea temporal para copiar el texto.
        var $temp = $("<textarea>");
        $("body").append($temp);
        $temp.val($("#textToCopy").text()).select();
        document.execCommand("copy");
        $temp.remove();

        // Aquí es donde puedes usar GSAP para animar el botón o el texto después de copiar
        gsap.to("#copy-direction-home", {
            duration: 0.2,
            scale: 0.9,
            ease: "power1.out",
            yoyo: true,
            repeat: 1,
        });
        // Mostrar el mensaje de confirmación con una animación.
        $("#copyConfirm").css("display", "block");
        gsap.fromTo(
            "#copyConfirm",
            { opacity: 0, y: -20 },
            { opacity: 1, y: 0, duration: 0.5, ease: "power1.out" }
        );
        // Opcional: ocultar el mensaje después de un tiempo con una animación.
        gsap.to("#copyConfirm", {
            opacity: 0,
            y: -20,
            delay: 2,
            duration: 0.5,
            ease: "power1.in",
            onComplete: function () {
                $("#copyConfirm").css("display", "none");
            },
        });
    });

    $(".copy-link").click(function (event) {
        var $temp = $("<textarea>");
        $("body").append($temp);
        $temp.val($("#textToCopy").text()).select();
        document.execCommand("copy");
        $temp.remove();

        $("#copyLinkConfirm").css("display", "block");

        gsap.to("#copy-link", {
            duration: 0.2,
            scale: 0.9,
            ease: "power1.out",
            yoyo: true,
            repeat: 1,
        });
        gsap.fromTo(
            "#copyLinkConfirm",
            { opacity: 0, y: -10 },
            { opacity: 1, y: 0, duration: 0.5, ease: "power1.out" }
        );
        gsap.to("#copyLinkConfirm", {
            opacity: 0,
            y: -10,
            delay: 2,
            duration: 0.5,
            ease: "power1.in",
            onComplete: function () {
                $("#copyLinkConfirm").css("display", "none");
            },
        });
    });
    // modals
    $(".modaal").modaal();
});

$("#modalPrivacidadLink, #modalPolitica").click(function () {
    TweenMax.to("#modalPrivacidad", 0.5, {
        opacity: 1,
        visibility: "visible",
        zIndex: 9999,
    });
});

$("#modalTerminosLink, #modalTermino").click(function () {
    TweenMax.to("#modalTerminos", 0.5, {
        opacity: 1,
        visibility: "visible",
        zIndex: 9999,
    });
});

$("#modalCookiesLink").click(function () {
    TweenMax.to("#modalCookies", 0.5, {
        opacity: 1,
        visibility: "visible",
        zIndex: 9999,
    });
});

$("#modalFAQLink").click(function () {
    TweenMax.to("#modalFAQ", 0.5, {
        opacity: 1,
        visibility: "visible",
        zIndex: 9999,
    });
});

$(".close-modal").click(function () {
    TweenMax.to(
        "#modalPrivacidad, #modalTerminos, #modalCookies, #modalFAQ",
        0.5,
        {
            opacity: 0,
            visibility: "hidden",
            zIndex: "-1",
        }
    );
});
